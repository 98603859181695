import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { presetComponentProps } from 'theme';
import { PageQueryRes } from 'types/cms/queries';
import HeroCard from 'components/MyExtras/HeroCard';
import { useStore } from 'store';
import { checkPointsExpiry, getStringFormattedDate } from 'utils/dateUtils';
import ExpiryWarning from './ExpiryWarning';
import LDLink from 'components/common/LDLink';
import { URLS } from 'utils/constants';

export interface PageHeroProps
  extends Pick<
    PageQueryRes,
    'headerBackgroundColor' | 'headerIconPattern' | 'headerImage' | 'headlineH1' | 'headlineH1Part2'
  > {
  useSlimHero?: boolean;
  isExtrasDashboard?: boolean;
}

const PageHero = ({
  headerBackgroundColor,
  headerIconPattern,
  headerImage,
  headlineH1,
  headlineH1Part2,
  useSlimHero,
  isExtrasDashboard
}: PageHeroProps): JSX.Element => {
  // Used to determine the line under the hero. When bg color is extras green, always use ld blue
  const heroDividerColor = headerBackgroundColor === '#9FD900' ? 'primary.default' : 'extras.olive';
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { extrasStore } = useStore();
  const { pointsInfo } = extrasStore?.heroCardData();
  const doPointsExpireInThirtyDays = pointsInfo?.balance > 0 && checkPointsExpiry(pointsInfo?.rewardTimeToLive);
  const formattedRewardTimeToLive =
    doPointsExpireInThirtyDays ? getStringFormattedDate(pointsInfo?.rewardTimeToLive) : "";
  const isOldSession = extrasStore?.isOldSession;
  const height = isExtrasDashboard
    ? 'initial'
    : {
      base: useSlimHero ? '10.625rem' : '10.625rem',
      sm: useSlimHero ? '13.75rem' : '13.75rem',
      md: useSlimHero ? '11.0625rem' : '16.875rem'
    };

  const handlePopupClose = () => {
    extrasStore?.setSessionValue();
    onClose();
  };
  useEffect(() => {
    if (doPointsExpireInThirtyDays) {
      onOpen();
    }
  }, [doPointsExpireInThirtyDays, onOpen]);

  const getHeroBanner = () => {
    if (doPointsExpireInThirtyDays) {
      if (isOldSession) {
        return (
          <Flex
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
            paddingBottom={{ base: '6', md: '66px' }}
            display="flex"
            flexDirection="column"
          >
            <ExpiryWarning
              firstName={extrasStore?.firstName}
              rewardTimeToLive={formattedRewardTimeToLive}
            />
            <Box paddingX="5">
              <HeroCard />
            </Box>
          </Flex>
        );
      } else {
        return (
          <>
            <Flex
              width="100%"
              height="100%"
              justifyContent="center"
              alignItems="center"
              paddingX="5"
              paddingY={{ base: '6', md: '66px' }}
              flexDirection="row"
            >
              {doPointsExpireInThirtyDays && (
                <Modal isOpen={isOpen} onClose={handlePopupClose}>
                  <ModalOverlay />
                  <ModalContent bgSize="auto" marginX={{ base: '5px' }} marginY="200px" alignItems="center">
                    <ModalBody>
                      <Text color="primary" sx={{ paddingTop: "5px" }}>
                        {extrasStore?.firstName}, you've worked hard to collect your LDExtras points
                        which are nearing expiration. You have options to maintain your points; make
                        any eligible purchase at London Drugs or{' '}
                        <LDLink href={'https://www.londondrugs.com'} textDecoration="underline">
                          londondrugs.com
                        </LDLink>{' '}
                        , or redeem a reward in the{' '}
                        <LDLink href={URLS.MY_EXTRAS_REWARDS} textDecoration="underline">
                          "Rewards & Vouchers"
                        </LDLink>{' '}
                        section before: {formattedRewardTimeToLive}.
                      </Text>
                    </ModalBody>
                    <ModalFooter sx={{ display: "flex", alignItem: "center" }}>
                      <Button
                        size='lg'
                        onClick={handlePopupClose}
                        color="white"
                        bgColor="primary.dark"
                        _hover={{ bgColor: 'primary.default' }}
                        alignSelf="center"
                      >
                        Okay
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              )}
              <HeroCard />
            </Flex>
          </>
        );
      }
    } else {
      return (
        <Flex
          width="100%"
          height="100%"
          alignItems="center"
          paddingX="5"
          paddingY={{ base: '6', md: '66px' }}
        >
          <HeroCard />
        </Flex>
      );
    }
  };

  return (
    <>
      <Box
        h={height}
        w="100%"
        bgColor={headerBackgroundColor}
        backgroundImage={`url(${headerIconPattern?.url})`}
        backgroundSize="108px"
      >
        {isExtrasDashboard ? (
          <>{getHeroBanner()}</>
        ) : (
          <Box
            h="100%"
            d="flex"
            flexDir="row"
            justifyContent="space-between"
            maxW="container.xxl"
            m="auto"
            px={{ base: 0, xl: '1rem' }}
          >
            {/* Used for alignment on desktop */}
            <Box w="10%" d={{ base: 'none', '2xl': 'block' }} />
            <Box
              d="flex"
              justifyContent="center"
              alignItems="center"
              w={{ base: '100%', md: '62%', '2xl': '50%' }}
            >
              <Heading
                {...presetComponentProps.h1}
                color="primary.default"
                d="flex"
                flexDir={{ base: 'column', lg: 'row' }}
                alignItems="center"
              >
                <Box as="span">{headlineH1}</Box>
                {headlineH1Part2 && (
                  <Box as="span" ml={{ base: '0', lg: '2' }} color="white">
                    {headlineH1Part2}
                  </Box>
                )}
              </Heading>
            </Box>
            <Box
              backgroundRepeat="no-repeat"
              backgroundPosition="right"
              backgroundSize="cover"
              backgroundImage={`url(${headerImage?.url})`}
              clipPath="polygon(12% 0%, 100% 0%, 100% 100%, 0% 100%)"
              w="40%"
              role={headerImage?.description ? 'img' : undefined}
              aria-label={headerImage?.description}
              d={{ base: 'none', md: 'block' }}
            />
          </Box>
        )}
      </Box>
      <Box mt="0.25rem" h={{ base: '0.25rem', sm: '0.5rem' }} bgColor={heroDividerColor} />
    </>
  );
};

export default PageHero;
