import { Box, SimpleGrid, Text } from '@chakra-ui/layout';
import React, { ReactElement } from 'react';
import LDLink from 'components/common/LDLink';
import { ProgramFeatureWithImageCMSData } from 'types/cms/fragments';
import ProxiedImage from 'components/common/ProxiedImage';
import { useMediaQuery } from '@chakra-ui/media-query';
import { LightenDarkenColor } from 'utils';

export interface ProgramFeatureWithImageProps extends ProgramFeatureWithImageCMSData {
  index: number;
  isRightToLeft: boolean;
  isFinalOddItem?: boolean;
}

const SingleProgramWithImage = ({
  image,
  textBackgroundColor,
  textColor,
  text,
  index,
  linkUrl,
  isRightToLeft,
  isFinalOddItem
}: Omit<ProgramFeatureWithImageProps, '__typename'>) => {
  const [dir, setDir] = React.useState<'ltr' | 'rtl'>();
  const [isDesktop] = useMediaQuery('(min-width:480px)');
  React.useEffect(() => {
    const isEven = index % 2 === 0;
    const mobileDir = isEven ? 'ltr' : 'rtl';
    const desktopDir = isRightToLeft ? 'rtl' : 'ltr';
    if (isDesktop) {
      if (isFinalOddItem) {
        setDir('rtl');
      } else {
        setDir(desktopDir);
      }
    } else {
      setDir(mobileDir);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  return (
    <SimpleGrid columns={2} dir={dir}>
      <ProxiedImage
        src={image?.url}
        alt={image?.description ?? undefined}
        width={image?.width}
        height={image?.height}
      />
      <Box
        bg={textBackgroundColor}
        textAlign="center"
        cursor={linkUrl ? 'pointer' : undefined}
        _hover={
          linkUrl ?? true
            ? {
              bg: LightenDarkenColor(textBackgroundColor, -10),
              textDecoration: 'none',
              transition: '0.2s'
            }
            : undefined
        }
        d="flex"
        alignItems="center"
        justifyContent="center"
        px="2"
      >
        <Text textColor={textColor} id="text" fontSize={{ base: '15px', sm: '20px', md: '24px' }}>
          {text}
        </Text>
      </Box>
    </SimpleGrid>
  );
};

const ProgramFeatureWithImage = ({
  title,
  linkUrl,
  isFinalOddItem,
  ...programFeatureWithImageProps
}: ProgramFeatureWithImageProps): ReactElement => {
  const finalOddItemStyle = isFinalOddItem
    ? {
      d: 'flex',
      width: '100%',
      justifyContent: 'center',
      gridColumnStart: { base: 'initial', sm: 'span 2' }
    }
    : {};
  return (
    <Box key={title} {...finalOddItemStyle}>
      {linkUrl ? (
        <LDLink
          href={linkUrl}
          _hover={{ textDecoration: 'none' }}
          width={isFinalOddItem ? { base: '100%', sm: '50%' } : '100%'}
          aria-label={`${title} (Banner)`}
        >
          <SingleProgramWithImage
            isFinalOddItem={isFinalOddItem}
            {...programFeatureWithImageProps}
          />
        </LDLink>
      ) : (
        <SingleProgramWithImage isFinalOddItem={isFinalOddItem} {...programFeatureWithImageProps} />
      )}
    </Box>
  );
};

export default ProgramFeatureWithImage;
