import NextImage, { ImageProps } from 'next/image';
import { ReactElement } from 'react';

export const toBase64 = (str: string): string =>
  typeof window === 'undefined' ? Buffer.from(str).toString('base64') : window.btoa(str);

const shimmer = (w: number | string, h: number | string) => `
  <svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <linearGradient id="g">
        <stop stop-color="#e1e1e3" offset="20%" />
        <stop stop-color="#f1f1f1" offset="50%" />
        <stop stop-color="#e1e1e3" offset="70%" />
      </linearGradient>
    </defs>
    <rect width="${w}" height="${h}" fill="#e1e1e3" />
    <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
    <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
  </svg>
`;

// This component proxies image srcs through the project's api so that we don't have to worry about
// whitelisting every possible image domain in next.config and potentially missing some
const ProxiedImage = ({ src, ...props }: ImageProps): ReactElement | null => {
  const shouldBlur = props?.width && props?.height && +props.width > 40 && +props.height > 40;

  return src ? <NextImage
    {...props}
    src={src}
    placeholder={shouldBlur ? 'blur' : 'empty'}
    blurDataURL={
      // Can't use shouldBlur here for some reason
      props?.width && props?.height && +props.width > 40 && +props.height > 40
        ? `data:image/svg+xml;base64,${toBase64(shimmer(props.width, props.height))}`
        : undefined
    }
  /> : <></>
};

export default ProxiedImage;
