import { months } from "./constants";


export const getExpirationDate = (dateString: string): string => {
  const date = new Date(dateString);
  date.setDate(date.getDate() - 2) //Syncing with existing API changes
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit'
  }).format(date);
  return formattedDate;
};

export const checkPointsExpiry = (tierExpireDate: string): boolean => {
  if (!tierExpireDate) return false;
  const numberOfDays = 30;
  const currentDate = new Date()?.getTime();
  const expiryDate = new Date(tierExpireDate)?.getTime();
  const daysBeforeExpiry = (expiryDate - currentDate) / (1000 * 60 * 60 * 24)
  return daysBeforeExpiry < numberOfDays
}

export const getStringFormattedDate = (dateString: string): string => {
  const date = new Date(dateString);
  if (date) {
    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  }
  return ''
}